<template>
  <div>
    <Navbar></Navbar>
      <div class="container main_nopage">
        <div>
          <div>
            <img src="../assets/img/nopage.png"  class="nopageimg" alt="404-page">
          </div>
          <div class="no_page_title">
            Cтраница не найдена
          </div>
          <div class="no_page_btn">
            <router-link to="/">
              Главная страница
            </router-link>
          </div>
        </div>
      </div>
    <div class="nopage_bottom">
        <div class="no_page_left">
          <div class="no_page_right">
            <div class="container">
              <div class="row">
                <div class="col-xl-3 col-md-6 col-sm-12 col-12" >
                  <div class="no_page_card">
                    <div class="pattern_bg_card">
                      <div class="no_page_card_title">
                        Новости
                      </div>
                      <div class="no_page_btnn">
                        <router-link to="/news">{{ $store.state.language.lang[67] }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 col-12" >
                  <div class="no_page_card">
                    <div class="pattern_bg_card">
                      <div class="no_page_card_title">
                        Музеи
                      </div>
                      <div class="no_page_btnn">
                        <router-link to="/museum">{{ $store.state.language.lang[67] }}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 col-12" >
                  <div class="no_page_card">
                    <div class="pattern_bg_card">
                      <div class="no_page_card_title">
                        Руководство
                      </div>
                      <div class="no_page_btnn">
                        <router-link to="/agency/management">$store.state.language.lang[67]</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 col-12" >
                  <div class="no_page_card">
                    <div class="pattern_bg_card">
                      <div class="no_page_card_title">
                        Медиатека
                      </div>
                      <div class="no_page_btnn">
                        <router-link to="/media">{{$store.state.language.lang[67]}}</router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../components/Header/Navbar";
import Footer from "../components/Header/Footer";
export default {
  components: {Footer, Navbar}
}
</script>
